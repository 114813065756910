import {atom} from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export interface UserState {
    type: string;
    userId: bigint|undefined; // User Long ID (instance id)
    username: string|undefined; // User ID (string id)
    name: string|undefined; // User name
    email: string|undefined; // User email
    menu: string; // selected menu
    authed: boolean; // is authenticated
}

export const currentUser = atom<UserState>({
    key: 'currentUser',
    effects_UNSTABLE: [persistAtom],
    default: {
        type: 'Client',
        menu: 'home',
        userId: undefined,
        username: undefined,
        name: undefined,
        email: undefined,
        authed: false,
    }
});