import React from 'react';
import ReactDOM from 'react-dom/client';
import {RecoilRoot} from "recoil";

import "./i18n";

//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//css
import './resource/css/common.css';
import './resource/css/layout.css';



const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
  <RecoilRoot>
      <div className={"wrapper"}>
          {/*<React.StrictMode>*/}
          <App />
          {/*</React.StrictMode>*/}
      </div>
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
