
////////////////////////////////////////////////////
export interface PAGE_SEARCH_OPTION {
  currentPage: number,
  totalElements: number,
  sortField?: string,
  direction?: string,
  text: Map<string,string>,
  last: Date,
}

export interface SECURE_DOWNLOAD_OPTION {
  container: string,
  containerId: number
}

////////////////////////////////////////////////////
export interface CountResult {
  prop: string,
  value: number
}

////////////////////////////////////////////////////
// Login Reqeust
export interface IdLoginReq {
  type: string,
  username: string,
  password: string,
}

export interface NameLoginReq {
  type: string,
  name: string,
  email: string,
  key: string,
}

export interface OrderCount {
  orderCount:number,
  confirmCount:number,
  unconfirmedCount:number,
  downloadCount:number,
  resendReqCount:number,
  closeCount:number,
  cancelCount:number,
  rejectCount:number,
}

// Base DTO
export interface BaseDto {
  id?: number,
  createdAt?: Date,
  createdBy?: number,
  updatedAt?: Date,
  updatedBy?: number,
  deleted?: boolean,
  deletedAt?: Date,
  deletedBy?: number,
}

export interface BaseIdDto {
  id?: number,
  createdAt?: Date,
  createdBy?: number,
  deleted?: boolean,
}

// File Dto
export interface FileListDto extends BaseIdDto {
  name: string,
  contentType: string,
  revision?: number,
  url: string,
  charged?: boolean,
  maxDownload?: number,
  downloadCount: number,
  fileKey?: string
}

export interface FileDto extends FileListDto {
  comment?: string,
  size?: number,
}

export interface ChargeInfo {
  id: number,
  charged?: boolean,
  maxDownload?: number,
}

// User Nonce
export interface UserNonce {
  userId: number,
  fileId: number,
  fileName: string,
  nonce: string,
  createdAt: Date
}

// User Dto
export interface UserIdDto extends BaseIdDto{
  type?: string,
  name?: string,
  email?: string,
  key?: string,
}

export interface UserDto extends BaseDto {
  type: string,
  username: string,
  email?: string,
  name: string,
  key?: string,
  orderStat?: OrderCount,
  lastLoginAt?: Date,
}

// Order Dto
export interface OrderStateDto {
  state: ORDER_STATE,
  stateName: string,
  issuer: UserIdDto,
}

export enum ORDER_STATE {
  ORDER=1,
  CONFIRM=2,
  UNCONFIRMED=3,
  REJECT=4,
  SEND=5,
  RESEND=6,
  CANCEL=7,
  CLOSE=10,
  REFUND=11
}

export interface OrderDto extends BaseDto {
  state: ORDER_STATE,
  stateName: string,
  lastStateUpdatedAt: Date,
  product: UserIdDto,
  issuer: UserIdDto,
  maxDownload: number,
  downloadCount: number,
  attaches: FileListDto[],
  available: OrderStateDto[],
  reload?: Date,
}

export interface OrderIdDto extends BaseIdDto {
  state: number,
  stateName: string,
  issuer: UserIdDto,
}

// Product Dto
export enum PRODUCT_STATE {
  PREPARE=1,
  ACTIVE=2,
  PAUSE=3,
  TERMINATE=4,
}

export interface ProductIdDto extends BaseIdDto {
  type: string,
  name: string
}

export interface ProductDto extends BaseDto {
  type: string,
  name: string,
  state: PRODUCT_STATE,
  stateName: string,
  defaultMaxDownload: number,
  start?: Date,
  end?: Date,
  owners?: UserIdDto[],
  serviceFiles?: FileListDto[],
  deprecatedFiles?: FileListDto[],
}

// Audits

export enum AUDIT_TYPE {
  // System (100000)
  //  - General (100000)
  START_APPLICATION=101001, // Service 시작")
  STOP_APPLICATION=101002, // Service 종료,
  //  - Security (101900)
  INVALID_LOGIN_TRY=101901, // 로그인 실패,


  /////////////////////////////////////////////////////
  // File Audit
  /////////////////////////////////////////////////////

  // File (120000)
  FILE_CREATE=120001, // 파일 등록
  FILE_DELETE=120002, // 파일 삭제
  FILE_REGISTER=120003, // 정식 파일 등록
  FILE_ADD_REVISION=120004, // Revision 추가
  FILE_DELETE_REVISION=120005, // Revision 삭제
  FILE_CHANGE_REVISION=120006, // Revision 변경

  /////////////////////////////////////////////////////
  // User Audit
  /////////////////////////////////////////////////////

  // User (130000)
  USER_CREATE=130001, // 계정 생성
  USER_DELETE=130002, // 계정 삭제
  USER_UPDATE=130003, // 계정 정보 변경
  USER_PAUSE=130004, // 계정 정지
  USER_CONFIRM=130005, // 계정 사용 허가
  USER_STATE_CHANGE=130006, // 상태 변경
  USER_REJECT=130007, // 계정 사용 불허
  USER_DELETE_IDENTIFICATION=130008, // 개인정보 영구 삭제

  USER_AGREE_TERMS=130101, // 약관 동의
  USER_AGREE_PRIVACY=130102, // 개인 정보 수집 동의
  USER_AGREE_SENSITIVE=130103, // 개인 민감 정보 수집 동의
  USER_AGREE_MARKETING=130104, // 마케팅 정보 수신 동의
  USER_DISAGREE_MARKETING=130105, // 마케팅 정보 수신 비동의

  USER_MODIFY_PROFILE=130201, // 정보 수정
  USER_SOCIAL_LINK=130202, // Social login 연결
  USER_FIND_ID=130203, // ID 찾기
  USER_FIND_PASSWORD=130204, // 비밀번호 찾기
  USER_LOGIN=130301, // Login 성공
  USER_LOGIN_FAIL=130302, // Login 실패
  USER_LOGOUT=130303, // Logout



  /////////////////////////////////////////////////////
  // Product Audit
  /////////////////////////////////////////////////////

  // Product (140000)
  PRODUCT_CREATE=140001, // 상품 생성
  PRODUCT_DELETE=140002, // 상품 삭제
  PRODUCT_UPDATE=140003, // 상품 정보 변경
  PRODUCT_UPDATE_STATE=140004, // 상품 상태 변경
  PRODUCT_ATTACH_FILE=140005, // 첨부 파일 추가
  PRODUCT_DETACH_FILE=140006, // 첨부 파일 제거
  PRODUCT_UPDATE_CHARGED_FILE=140007, // 유/무료 파일 설정 변경


  // Order (150000)
  ORDER_CREATE=150001, // 주문 생성
  ORDER_DELETE=150002, // 주문 삭제
  ORDER_UPDATE=150003, // 주문 변경
  ORDER_STATE_CHANGE=150004, // 상태 변경

  ORDER_REQUEST=151001, //  Order 생성 시
  ORDER_RE_SEND=151002, //  사용자 재전송 요청 시
  ORDER_CONFIRM=151011, //  구매 승인 시
  ORDER_REJECT=151021, // 판매자 거절 시
  ORDER_CANCEL=151022, //  주문자 취소 시
  ORDER_DROP=151023, // 구매 자동 거절  (사용하지 않음)
  ORDER_MAIL_SEND=151031, // 상품 전송 시 (mail 전송 후)
  ORDER_DOWNLOAD=151032, // 상품 다운로드
  ORDER_CLOSE=151033, // 주문 정상 종료
  ORDER_UNCONFIRMED=151034, // 입금 확인 안됨
  ORDER_RE_ORDER=151035, // 사용자 재주문
  ORDER_REFUND=151036, // 판매자 환불 처리
}

// Audit DTO
export interface AuditDto {
  id: number,
  requestId: string,
  createdAt: Date,
  auditType: AUDIT_TYPE,
  auditTypeName: string,
  message?: string,
  user?: UserIdDto,
}

export enum CUSTOM_MODAL_CLASS{
  SHOW="isVisible",
  CLOSE=""
}



