// Utilities

import {BUTTON_CLASS, COLOR, ITEMS_PER_PAGE, SPAN_CLASS} from "../const";
import {Dispatch, ReactNode, SetStateAction} from "react";
import {
  AUDIT_TYPE,
  FileListDto,
  ORDER_STATE,
  PAGE_SEARCH_OPTION,
  PRODUCT_STATE,
  SECURE_DOWNLOAD_OPTION,
  UserNonce
} from "../types";
import {message, TablePaginationConfig} from "antd";
import {FilterValue} from "antd/es/table/interface";
import {Resetter} from "recoil";
import {API} from "../config";

export const HEADER = () => {
    const header: HeadersInit = new Headers();
    header.set('Content-Type', 'application/json');

    return header;
}

// Check NULL or Empty string
export const NULL_OR_EMPTY_STRING = (value:string|null|undefined): boolean => {
    return (!value || value.trim().length === 0);
}

// 임의의 Data type을 String으로 변환
export const STRING = (value:any|undefined): string => {
  return !!value ? value.toString() : '';
}

// 임의의 Data type을 Number로 변환
export const NUMBER = (value:any|undefined): number => {
  return !value ? 0 : parseInt(value.toString(), 10);
}

// Equal
export const EQUAL = (o1:any|undefined, o2:any|undefined): boolean => {
  return (!o1 || !o2) ? false : o1.toString() === o2.toString();
}

// Json object 를 string으로 변환 (null 제외)
export const JSON_TO_STRING = (o:any): string => {
  return JSON.stringify(o, (k, v) => v ?? undefined);
}

/////////////////////////////////////////////////////////////////////////////////////
export const FOCUS_COLOR = (value: number, forceColor: string): string => {
    return value > 0 ? forceColor : COLOR.gray;
}

export const ORDER_STATE_COLOR = (state: ORDER_STATE): string => {
  let color = COLOR.gray;
  switch (state) {
    case ORDER_STATE.ORDER: color = COLOR.red_03; break; // ORDER
    case ORDER_STATE.CONFIRM: color = COLOR.green_01; break; // CONFIRM
    case ORDER_STATE.UNCONFIRMED: color = COLOR.red_02; break; // UNCONFIRMED
    case ORDER_STATE.REJECT: color = COLOR.red_01; break; // REJECT
    case ORDER_STATE.RESEND: color = COLOR.purple_02; break; // RESEND
    case ORDER_STATE.CANCEL: color = COLOR.purple_02; break; // Cancel
    case ORDER_STATE.SEND: color = COLOR.blue_03; break; // SEND
    case ORDER_STATE.CLOSE: color = COLOR.blue_02; break; // CLOSE
    case ORDER_STATE.REFUND: color = COLOR.purple_02; break; // REFUND
  }
  return color;
}

export const ORDER_STATE_NAME = (state: ORDER_STATE): string => {
  let name = 'order.state.order';
  switch (state) {
    case ORDER_STATE.ORDER: name = 'order.state.order'; break; // ORDER
    case ORDER_STATE.CONFIRM: name = 'order.state.confirm"'; break; // CONFIRM
    case ORDER_STATE.UNCONFIRMED: name = 'order.state.unconfirmed'; break; // UNCONFIRMED
    case ORDER_STATE.REJECT: name = 'order.state.reject'; break; // REJECT
    case ORDER_STATE.RESEND: name ='order.state.resend'; break; // RESEND
    case ORDER_STATE.CANCEL: name = 'order.state.cancel'; break; // Cancel
    case ORDER_STATE.SEND: name = 'order.state.send'; break; // SEND
    case ORDER_STATE.CLOSE: name = 'order.state.close'; break; // CLOSE
    case ORDER_STATE.REFUND: name = 'order.state.cancel'; break; // REFUND
  }
  return name;
}


export const SPAN_ORDER_STATE_COLOR_CLASS = (state:ORDER_STATE):string =>{
  let className = SPAN_CLASS.CLOSE;

  switch (state) {
    case ORDER_STATE.ORDER: className = SPAN_CLASS.ORDER; break;
    case ORDER_STATE.CONFIRM: className = SPAN_CLASS.CONFIRM; break;
    case ORDER_STATE.UNCONFIRMED: className = SPAN_CLASS.UNCONFIRMED; break;
    case ORDER_STATE.REJECT: className = SPAN_CLASS.REJECT; break;
    case ORDER_STATE.SEND: className = SPAN_CLASS.SEND; break;
    case ORDER_STATE.RESEND: className = SPAN_CLASS.RESEND; break;
    case ORDER_STATE.CANCEL: className = SPAN_CLASS.CANCEL; break;
    case ORDER_STATE.CLOSE: className = SPAN_CLASS.CLOSE; break;
    case ORDER_STATE.REFUND: className = SPAN_CLASS.REFUND; break; // REFUND
  }

  return className;
}

export const BUTTON_ORDER_STATE_COLOR_CLASS = (state:ORDER_STATE):string =>{
  let className = BUTTON_CLASS.CLOSE;

  switch (state) {
    case ORDER_STATE.ORDER: className = BUTTON_CLASS.ORDER; break;
    case ORDER_STATE.CONFIRM: className = BUTTON_CLASS.CONFIRM; break;
    case ORDER_STATE.UNCONFIRMED: className = BUTTON_CLASS.UNCONFIRMED; break;
    case ORDER_STATE.REJECT: className = BUTTON_CLASS.REJECT; break;
    case ORDER_STATE.SEND: className = BUTTON_CLASS.SEND; break;
    case ORDER_STATE.RESEND: className = BUTTON_CLASS.RESEND; break;
    case ORDER_STATE.CANCEL: className = BUTTON_CLASS.CANCEL; break;
    case ORDER_STATE.CLOSE: className = BUTTON_CLASS.CLOSE; break;
    case ORDER_STATE.REFUND: className = BUTTON_CLASS.REFUND; break; // REFUND
  }

  return className;
}

export const PRODUCT_STATE_COLOR = (state: PRODUCT_STATE): string => {
  let color = COLOR.gray;
  switch (state) {
    case PRODUCT_STATE.PREPARE: color = COLOR.purple_01; break;
    case PRODUCT_STATE.ACTIVE: color = COLOR.green_01; break;
    case PRODUCT_STATE.PAUSE: color = COLOR.red_02; break;
    case PRODUCT_STATE.TERMINATE: color = COLOR.gray; break;
  }
  return color;
}

export const AUDIT_HISTORY_COLOR = (auditType: AUDIT_TYPE): string => {
  let color = COLOR.blue_01;
  switch (auditType) {
    // Product Audit
    case AUDIT_TYPE.PRODUCT_ATTACH_FILE:
      color = COLOR.green_01; break;
    case AUDIT_TYPE.PRODUCT_DETACH_FILE:
      color = COLOR.red_03; break;

    // Order Audit
    case AUDIT_TYPE.ORDER_REQUEST: // ORDER
      color = COLOR.red_03; break;
    case AUDIT_TYPE.ORDER_RE_SEND: // RESEND
      color = COLOR.purple_02; break;
    case AUDIT_TYPE.ORDER_CONFIRM: // CONFIRM
      color = COLOR.green_01; break;
    case AUDIT_TYPE.ORDER_MAIL_SEND: // SEND
      color = COLOR.blue_03; break;
    case AUDIT_TYPE.ORDER_REJECT: // REJECT
      color = COLOR.red_01; break;
    case AUDIT_TYPE.ORDER_CANCEL: // CANCEL
      color = COLOR.purple_02; break;
  }
  return color;
}

export const ERROR_MESSAGE = (error:Error, defaultMessage:string) => {
  try {
    const emsg = !NULL_OR_EMPTY_STRING(error.message) ? JSON.parse(error.message).message : defaultMessage;
    console.error(emsg);
    return emsg;
  } catch (e) {
    console.error('Error Message parsing fail!!');
    return "Unknown ERROR or Connect to Failed";
  }
}

export const FILE_AVATA_ICON = (fileInfo:FileListDto): ReactNode => {
  return `/img/file-${fileInfo.charged ? 'lock' : 'unlock'}.png`
}

// Timeline history 에서 new line 이 있으면 <br/>로 변경
export const HISTORY = (history?:string): string => {
  return !!history ? history.replace(/\n/g,"<br/>") : '';
}


/////////////////////////////////////////////////////////////////////////////////////
export const CHECK_RESPONSE = async (res:Response, resetRecoilState:Resetter): Promise<any> =>{
  if (res.ok) return res.json();
  if (res.status === 401) resetRecoilState();
  throw new Error(await res.text());
}

export const TABLE_FETCH = (
  url:string,
  setTableDataSource:Dispatch<SetStateAction<never[]>>,
  searchOptions:any,
  setSearchOptions:Dispatch<SetStateAction<any>>,
  resetRecoilState:Resetter,
  errorMessage:string
) => {
    console.log(`CALL: ${url}`); // logging
    fetch(url, {
        method: "GET",
        headers: HEADER(),
    })
      .then(res => CHECK_RESPONSE(res, resetRecoilState))
      .then(res => {
          setSearchOptions({
              ...searchOptions,
              currentPage: res.number + 1,
              totalElements: res.totalElements
          });
          setTableDataSource(res.content);
      })
      .catch(error => {
          message.open({type: 'error', content: ERROR_MESSAGE(error, errorMessage)});
      })
}

export const RESET_PAGE_SEARCH_PARAM = (param: URLSearchParams, searchOptions:PAGE_SEARCH_OPTION) => {
  param.append('page', (searchOptions.currentPage-1).toString());
  param.append('size', ITEMS_PER_PAGE.toString());
  if(searchOptions.text.size>0) {
    searchOptions.text.forEach((v,k,_) => {
      param.append('filter', `${k}*%${v}%`);
    });
  }
  if (!searchOptions.sortField || !searchOptions.direction) {
    param.append('sort', 'id,desc');
  } else {
    param.append('sort', `${searchOptions.sortField},${searchOptions.direction === 'ascend' ? 'asc' : 'desc'}`);
  }
}

// onTableChange Event handler
export const ON_TABLE_CHANGE = (
  searchOptions:PAGE_SEARCH_OPTION, setSearchOptions: Dispatch<SetStateAction<PAGE_SEARCH_OPTION>>, // searchOption parameters
  pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any // onTableChange parameters
) => {
  // isUpdate
  let bUpdate = false;
  const copy = structuredClone(searchOptions);

  // Page number handling
  const pageNumber = !!pagination.current ? pagination.current : 1;
  if (copy.currentPage !== (!!pagination.current ? pagination.current : 1)) {
    copy.currentPage = pageNumber;
    bUpdate = true;
  }
  if (!!sorter && (copy.sortField !== sorter.field || copy.direction !== sorter.order)) {
    copy.sortField = sorter.field;
    copy.direction = sorter.order;
    bUpdate = true;
  }
  if (bUpdate) {
    setSearchOptions({...copy, last: new Date()});
  }
}

// Download URL 생성
export const MK_DOWNLOAD_URL = (url:string, secure?:SECURE_DOWNLOAD_OPTION) => {
  if (!!secure) { // Secured URL
    return `${url.replace(/\/file\/i\//,"/file/p/")}?act=download&container=${secure.container}&containerId=${secure.containerId}`;
  } else {
    return `${url}?act=download`;
  }
}

export const DOWNLOAD_FILE = (
  fileInfo:FileListDto,
  resetRecoilState:Resetter,
  defaultErrorMessage?:string,
  reloadSet?: Dispatch<SetStateAction<Date|undefined>>,
  fileKeySet?: Dispatch<SetStateAction<String|undefined>>
) => {

  const header: HeadersInit = new Headers();
  header.set('Content-Type', fileInfo.contentType);
  fetch(fileInfo.url, {
    method: "GET",
    headers: header
  })
    .then(async res => {
      if (res.ok) return res.blob();
      if (res.status === 401) resetRecoilState();
      throw new Error(await res.text());
    })
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        fileInfo.name,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();


      if(fileInfo.charged && fileInfo.maxDownload !== undefined && fileInfo.downloadCount < fileInfo.maxDownload){
          getFileKey(fileInfo.id, resetRecoilState, defaultErrorMessage, fileKeySet);

      }
      //파일키 요청



      // Clean up and remove the link
      if (link.parentNode) link.parentNode.removeChild(link);

      if (!!reloadSet) reloadSet(new Date()); // reload

    })
    .catch(error => {
      message.open({type: 'error', content: ERROR_MESSAGE(error, defaultErrorMessage ? defaultErrorMessage : "Unknown error")});
    });
}


const getFileKey = (
    fileId:number|undefined,
    resetRecoilState:Resetter,
    defaultErrorMessage?:string,
    fileKeySet?: Dispatch<SetStateAction<String|undefined>>) =>{


  fetch(`${API.USER}/paid/key?fileId=${fileId}`, {
    method: "GET",
    headers: HEADER(),
  })
      .then(res => CHECK_RESPONSE(res, resetRecoilState))
      .then((res:UserNonce[]) => {

        if(res.length > 0){
          if(!!fileKeySet) fileKeySet(res[0].nonce);
        }

      })
      .catch(error => {
        message.open({type: 'error', content: ERROR_MESSAGE(error, defaultErrorMessage ? defaultErrorMessage : "Unknown error")});
      });


}