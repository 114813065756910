import {useRecoilState, useResetRecoilState} from "recoil";

import {Button, Image, MenuProps} from "antd";
import {useLocation, useNavigate} from "react-router-dom"

import {currentUser} from "../state";
import {useTranslation} from "react-i18next";
import {API} from "../config";
import {HEADER} from "../utils";

//image
import logo from '../resource/img/logo.png';


const menuTargets = [
  { key: "home", target: `/`},

  { key: "login", target: `/login`},
  { key: "logout"},
];

export const MainMenu = () => {
  // recoil
  const [userState, setUserState] = useRecoilState(currentUser);
  const resetUserState = useResetRecoilState(currentUser);

  // i18n
  const {t} = useTranslation();

  // navigate
  const navigate = useNavigate();

  // menus
  const items: MenuProps['items'] = [
    // {
    //   label: userState.name +' 님',
    //   key: 'username',
    // },
    {
      label: t("user.logout"),
      key: 'logout',
      //icon: <LogoutOutlined />,
      disabled: !userState.userId,
    },
  ];

  const onLogout = () =>{
    fetch(`${API.AUTH}/logout`, {
      method: "POST",
      headers: HEADER()
    }).then(res => {
      if (res.ok) {
        resetUserState();
      }
    })
  };



  const onClick: MenuProps['onClick'] = (e) => {
    setUserState({...userState, menu: e.key})
    const { target } = menuTargets.find(item => item.key === e.key) || {};
    if (e.key === 'logout') {
      fetch(`${API.AUTH}/logout`, {
        method: "POST",
        headers: HEADER()
      }).then(res => {
        if (res.ok) {
          resetUserState();
        }
      })
    } else if (target) {
      navigate(target);
    }
  };

  //login Page에서 나오지 않게
  if (useLocation().pathname === '/login') return null;

  return (
      <header>
        <h1><Image src={logo} alt="필승전략 로고" preview={false}/></h1>
        <ul className={"user_navi"}>
          <li>{userState.name +' 님 ('+ userState.email+')'}</li>
          <li>
            <Button
              onClick={onLogout}
            >로그아웃</Button>
          </li>
        </ul>
        {/*<Menu className={"user_navi"} onClick={onClick} selectedKeys={[userState.menu]} mode="horizontal" items={items} />*/}
      </header>
  );
};