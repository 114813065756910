import React, {useEffect} from 'react';

import {BrowserRouter, Route, Routes} from "react-router-dom";

import {MainMenu} from "./components/MainMenu"
import {SignIn} from "./components/root/SignIn";
import {useRecoilValue} from "recoil";
import {currentUser} from "./state";
import AuthRoute, {AuthRouteProps} from "./AuthRoute";
import {OrderDetail} from "./components/product/OrderDetail";
import Invalid from "./components/root/Invalid";

function App() {
  // recoil
  const userState = useRecoilValue(currentUser);

  useEffect(() => {
    console.log("currentMenu: ", userState.menu, " auth:", userState.authed);
  }, [userState.menu, userState.authed]);

  const defaultAuthRouteProps: Omit<AuthRouteProps, 'outlet'> = {
    isAuthenticated: !!userState.userId,
    authenticationPath: '/login',
  };

  return (
    <BrowserRouter>
      <MainMenu />
      <Routes>
        <Route path="/" element={<AuthRoute {...defaultAuthRouteProps} outlet={<OrderDetail />} />} />
{/*        <Route path="/order/:id" element={<AuthRoute {...defaultAuthRouteProps} outlet={<OrderDetail />} />} />*/}

        <Route path="/login" element={<SignIn />} />

        <Route path={"*"} element={<Invalid />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
