import React, {useState} from 'react';
import {Button, Form, Input, Image, message, Modal} from 'antd';

import {useTranslation} from "react-i18next";

import {API} from "../../config";
import {ERROR_MESSAGE, HEADER} from "../../utils";
import {NameLoginReq as LoginReq} from "../../types";
import {useRecoilState} from "recoil";
import {currentUser} from "../../state";
import {Navigate} from "react-router-dom";


//image
import logo from '../../resource/img/logo.png';

export const SignIn: React.FC = () => {
    // recoil
    const [userState, setUserState] = useRecoilState(currentUser);

    // i18n
    const { t} = useTranslation();

    const [ isEmailOpen, setIsEmailOpen ] = useState(false);

    const { confirm } = Modal;


    const onFinish = (values: any) => {
        console.log('Login user ID: ', values.username);

        if (userState.authed) return <Navigate to="/" />; // 인증된 경우

        fetch(`${API.AUTH}/login`, {
            method: "POST",
            headers: HEADER(),
            body: JSON.stringify({
                type: "Name",
                name: values.name,
                email: values.email,
                key: values.key
            } as LoginReq)
        })
            .then(async res => {
                console.log(res)
                    if (res.ok) {
                        return res.json()
                    }
                    switch (res.status) {
                        case 400: break;
                        case 401: // 아직 생성되지 않은 사용자 또는 email이 지정되지 않은 경우
                            // email 입력 창이 필요하다
                            message.error(t('warning.signin.fail'))
                            break;
                        default:
                            const text = await res.text();
                            throw new Error(text);
                    }
                }
            )
            .then(res => {
                if (res !== undefined) {
                    setUserState({
                        ...userState,
                        type: res.type,
                        userId: res.id,
                        username: res.username,
                        name: res.name,
                        email: res.email,
                        authed: res.type === 'Client' && !!res.id
                    })
                }
            })
            .catch(error => {
                message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.signin.fail"))});
            })
    };

    if (userState.authed) {
        return <Navigate to="/" />;
    }

    return (
        <>
                <div className={"loginBox"}>
                    <h1><Image src={logo} alt="필승전략 로고" preview={false}/></h1>

                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: false,
                        }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <div className={"form_input"}>


                            <section className={"width100"}>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("message.required.email"),
                                        },
                                        () => ({
                                            validator(_, value) {
                                                const regx = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

                                                if(!value || regx.test(value)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error(t("message.required.emailRegx")));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        className={"des_input"}
                                        //   prefix={<MailOutlined className="site-form-item-icon" />}
                                        placeholder={t("user.email")}
                                        // disable copy & paste
                                        onPaste={(e)=>{
                                            e.preventDefault()
                                            return false;
                                        }}
                                        onCopy={(e)=>{
                                            e.preventDefault()
                                            return false;
                                        }}
                                    />
                                </Form.Item>
                            </section>
                            <section className={"width100"}>
                                <Form.Item
                                    name="confirm-email"
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: t("message.required.email"),
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('email') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error(t("message.required.emailConfirm")));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        className={"des_input"}
                                        //   prefix={<MailOutlined className="site-form-item-icon" />}
                                        placeholder={t("user.emailConfirm")}
                                        // disable copy & paste
                                        onPaste={(e)=>{
                                            e.preventDefault()
                                            return false;
                                        }}
                                        onCopy={(e)=>{
                                            e.preventDefault()
                                            return false;
                                        }}
                                    />
                                </Form.Item>

                            </section>

                            <section className={"width100"}>
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("message.required.name"),
                                        },
                                    ]}
                                >
                                    <Input
                                        className={"des_input"}
                                        //   prefix={<UserOutlined className="site-form-item-icon" />}
                                        placeholder={t("user.name")}
                                    />
                                </Form.Item>
                            </section>

                            <section className={"width100 marginB0"}>
                                <Form.Item
                                    name="key"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("message.required.key"),
                                        },
                                        () => ({
                                            validator(_, value) {
                                                const regx = /^[0-9]{4}$/;

                                                if(!value || regx.test(value)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error(t("message.required.key")));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        className={"des_input"}
                                        // prefix={<NumberOutlined className="site-form-item-icon" />}

                                        placeholder={t("user.key")}
                                        // disable copy & paste
                                        onPaste={(e)=>{
                                            e.preventDefault()
                                            return false;
                                        }}
                                        onCopy={(e)=>{
                                            e.preventDefault()
                                            return false;
                                        }}
                                    />
                                </Form.Item>

                            </section>

                            <section className={"width100 new_order_text list_deco"}>
                                <p>{t('message.required.keyNotice.3')}</p>
                            </section>

                            <section className={"width100 new_order_text list_deco"}>
                                <p>{t('message.signIn.0')}<span className={"keypoint_word"}>{t('message.signIn.1')}</span>{t('message.signIn.2')}</p>
                            </section>

                        </div>

                        <div className={"form_btn"}>
                            <Form.Item>
                                <Button htmlType="submit" className={"des_btn_default des_btn_main"}>
                                    {t("user.login")}
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
        </>
    )
};