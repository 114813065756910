import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {
  AUDIT_HISTORY_COLOR,
  BUTTON_ORDER_STATE_COLOR_CLASS,
  CHECK_RESPONSE,
  DOWNLOAD_FILE,
  ERROR_MESSAGE,
  HEADER,
  ORDER_STATE_NAME,
  SPAN_ORDER_STATE_COLOR_CLASS
} from "../../utils";
import {useTranslation} from "react-i18next";
import type {InputRef} from 'antd';
import {Button, Card, Descriptions, Flex, Input, message, Modal, Space, Timeline, TimelineItemProps} from "antd";
import {API} from "../../config";
import {
  AUDIT_TYPE,
  AuditDto,
  CUSTOM_MODAL_CLASS,
  FileListDto,
  ORDER_STATE,
  OrderDto,
  OrderIdDto,
  UserNonce
} from "../../types";
import {DescriptionsItemType} from "antd/es/descriptions";
import moment from "moment/moment";
import {useResetRecoilState} from "recoil";
import {currentUser} from "../../state";
import {CopyOutlined} from "@ant-design/icons";

export const OrderDetail = () => {
  // Recoil
  const resetUserState = useResetRecoilState(currentUser);

  // i18n
  const { t } = useTranslation();

  // message
  const [messageApi, contextHolder] = message.useMessage();

  // interface
  interface OrderAuditDto extends AuditDto {
    order: OrderIdDto,
    totalElements: number,
    numberOfElements:number,
    size:number,
    raw?:string
  }

  interface historySearchOption {
    size:number,
    totalElements:number
    enabled:boolean
  }

  // Order ID
  const params = useParams();
  //추후 추가 필요
  const [orderId, setOrderId] = useState<number>();
  //const orderId = params.id;
  // Reload
  const [reload, setReload] = useState<Date>();
  //file key
  const [ fileKey, setFileKey] =useState<String>();
  //bankNum
  const [bankNum, setBankNum] = useState<String>(t('bank.number'))
  // resend
  const [reason, setReason] = useState<string>("");
  const [reasonModalClass, setReasonModalClass] = useState<String>(CUSTOM_MODAL_CLASS.CLOSE);
  // Order detail
  const [orderInfo, setOrderInfo] = useState<OrderDto>();
  // Order history
  const [orderHistory, setOrderHistory] = useState<OrderAuditDto[]>([]);

  const [ historySearchOption, setHistorySearchOption ] = useState<historySearchOption>({
    size:5,
    totalElements:0,
    enabled: false
  });

  // Description
  const [items, setItems] = useState<DescriptionsItemType[]>([]);
  // History
  const [histories, setHistories] = useState<TimelineItemProps[]>([]);
  // Download file ID
  const [downloadFileInfo, setDownloadFileInfo] = useState<FileListDto>();
  // Find File Key Action
  const [actFindFileKey, setActFindFileKey] = useState<Date|undefined>(undefined);
  // confirm
  const [ modal, modalContextHolder ] = Modal.useModal();

  const inputRef = useRef<InputRef>(null);

  // Load Order Info
  const loadOrderInfo = () => {
    fetch(`${API.PRODUCT}/order`, {
      method: "GET",
      headers: HEADER(),
    })
        .then(res => CHECK_RESPONSE(res, resetUserState))
        .then(res => {
          if (res.totalElements > 0) {
            setOrderInfo(res.content[0] as OrderDto);
          }
        })
        .catch(error => {
          message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
        });
  }

  const isHistoryMoreButton = (totalElements:number, size:number):boolean => {
    let result = false;
    if(size < totalElements && size - totalElements  < 1){ result = true;}
    return result;
  }

  const loadOrderHistory =  (orderId:number|undefined, size:number) => {
    if(size > 0){
      setHistorySearchOption({
        ...historySearchOption,
        size: historySearchOption.size += size
      })
    }

    // 추후 상품이 많아지면 orderID 값 추가필요
    fetch(`${API.AUDIT}/order?mapping=detail&filter=auditType!:${AUDIT_TYPE.ORDER_STATE_CHANGE}&size=${historySearchOption.size}&sort=id,desc`, {
      method: "GET",
      headers: HEADER(),
    })
        .then(res => CHECK_RESPONSE(res, resetUserState))
        .then(res => {
          if (res.totalElements > 0) {
            setOrderHistory(res.content);
            setHistorySearchOption({
              size:res.size,
              totalElements:res.totalElements,
              enabled: isHistoryMoreButton(res.totalElements,res.size)
            });
          }
        })
        .catch(error => {
          message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
        });
  }

  const isAttachFilesByState = (state:number|undefined): boolean =>{
    switch (state){
      case ORDER_STATE.ORDER:
      case ORDER_STATE.UNCONFIRMED:
      case ORDER_STATE.REJECT:
      case ORDER_STATE.CLOSE: return false;
      default: return true;
    }
  }

  // 주문 상태 변경
  const onNextStateClick = (orderId:number|undefined, nextState: number) => {
    fetch(`${API.PRODUCT}/order/${orderId}/${nextState}`, {
      method: "PUT",
      headers: HEADER(),
    })
        .then(res => CHECK_RESPONSE(res, resetUserState))
        .then(res => {
          message.success(t('success.ok'));
          setReload(new Date()) // Reload pages
        })
        .catch(error => {
          message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
        });
  }

  const onNextStateReSendClick = (orderId:number|undefined) => {
    if(reason.trim() !== ""){
      fetch(`${API.PRODUCT}/order/${orderId}/${ORDER_STATE.RESEND}`, {
        method: "PUT",
        headers: HEADER(),
        body: `${reason}`

      })
          .then(res => CHECK_RESPONSE(res, resetUserState))
          .then(res => {
            message.success(t('success.ok'));
            setReasonModalClass(CUSTOM_MODAL_CLASS.CLOSE);
            setReason("");
            setReload(new Date()) // Reload pages
          })
          .catch(error => {
            message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
          });

    }else{
      message.error(t("message.required.resend"));
    }
  }

  useEffect(() => {
    if (!actFindFileKey) return;
    fetch(`${API.USER}/paid/key`, {
      method: "GET",
      headers: HEADER(),
    })
        .then(res => CHECK_RESPONSE(res, resetUserState))
        .then((res:UserNonce[]) => {
          message.success(t('success.ok'))
          orderInfo?.attaches.forEach(f => {
            const info = res.find(i => i.fileId === f.id);
            if (f.charged) {
              if (info) {
                f.fileKey = info.nonce;
              } else {
                f.fileKey = t("file.key_view_expire");
              }
            }
          })
          setOrderInfo({...orderInfo as OrderDto, reload: new Date()});
        })
        .catch(error => {
          message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
        });
  }, [actFindFileKey]);

  useEffect(() => {
    if (downloadFileInfo) {
      DOWNLOAD_FILE(downloadFileInfo, resetUserState, t("warning.unknown"), setReload, setFileKey);
    }
  }, [downloadFileInfo]);

  useEffect(() => {
    if(!!fileKey){
      modal.warning({
        title: (t('message.file.key')),
        content: (
            <>
              <Space.Compact>
                <Input
                    addonBefore={t('file.label.key')}
                    defaultValue={fileKey.toString()}
                    ref={inputRef}
                />

                <Button
                    icon={<CopyOutlined />}
                    onClick={() => {
                      if (fileKey !== null) {
                        window.navigator.clipboard.writeText(fileKey.toString());
                        message.success(t("file.label.key")+'가 '+t("message.copy"));
                      }
                    }
                    }

                ></Button>
              </Space.Compact>

              <p>파일 암호를 복사해주세요.</p>
              <p>분실시 찾을 수 없습니다.</p>
            </>
        ),
        okType: 'danger',
        okText: '확인',
      });
    }

  },[fileKey])

  // Refresh data
  useEffect(() => {
    loadOrderInfo();
    loadOrderHistory(orderInfo?.id, 0);
  }, [reload]);

  // Page loadding이 완료
  useEffect(() => {
    setReload(new Date())
  }, []);

  // Order 정보 조회
  useEffect(() => {
    if (!!orderInfo) {
      setItems([
        {
          key: 'state',
          label: t('table.title.orderState'),
          children: (
              <p>
              <span className={SPAN_ORDER_STATE_COLOR_CLASS(orderInfo.state)}>
                {t(ORDER_STATE_NAME(orderInfo.state))}
              </span>
              </p>
          ),
        },
        {
          key: 'issuer.name',
          label: t('table.title.issuer'),
          children: <p>{orderInfo.issuer.name}</p>,
        },
        {
          key: 'createdAt',
          label: t('table.title.issuedAt'),
          children: <p>{!!orderInfo.createdAt ? moment(orderInfo.createdAt).format('YYYY-MM-DD HH:mm:ss') : ''}</p>,
        },
        {
          key: 'downloadCount',
          label: t('table.title.downloadCount'),
          children: (
              <>
                {orderInfo.downloadCount > 0 ? orderInfo.downloadCount : 0} / {orderInfo.maxDownload}
              </>
          ),
        },
        {
          key:'bankNum',
          label:t('bank.title'),
          children: <>
            <p>{bankNum} &nbsp;&nbsp;&nbsp;
              <Button
                  icon={<CopyOutlined />}
                  onClick={() => {
                    if (fileKey !== null) {
                      window.navigator.clipboard.writeText(bankNum.toString());
                      message.success(t('bank.title')+'가 '+t("message.copy"));
                    }
                  }
                  }

              ></Button>


            </p>
            <p>{t('bank.name')}</p>
            <p>{t('bank.owner')}</p>
          </>,
        },
      ]);
    }
  }, [orderInfo]);

  // Order History 조회
  useEffect(() => {
    if (!!orderHistory && orderHistory.length > 0) {
      setHistories(orderHistory
          .map(h => ({
            color: AUDIT_HISTORY_COLOR(h.auditType),
            label: moment(h.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            children: (
                <>
                  <p>{h.message}</p>
                  {
                    h.auditType !== AUDIT_TYPE.ORDER_RE_SEND ? null :
                        h.raw !== null || h.raw !== undefined ?
                            <p>사유: {h.raw}</p> : null
                  }
                </>
            ),
          }))
      );
    }
  }, [orderHistory]);

  return(
      <>
        <main className={"order-detail"}>
          <h3>{t('title.order_info')}</h3>
          <div className={"wrap_tb"}>
            <Descriptions className={"des_tb des_tb_user"}  bordered items={items} />
            <Flex justify={"flex-end"}>
              <section className={"width100 account_text"}>
                <p className={"first-of-type"}>{t('message.required.keyNotice.0')} <span className={"keypoint_word"}>{t('message.required.keyNotice.1')}</span> {t('message.required.keyNotice.2')}</p>
                <p>{t('message.required.keyNotice.3')}</p>
                <p>{t('message.required.keyNotice.4')}</p>
              </section>
            </Flex>
            <div className={"wrap_btn"}>
              <Flex justify={"flex-end"}>
                {
                  orderInfo?.available.filter(st => st.state !== ORDER_STATE.CANCEL)
                      .map((st,index) => (
                          <button
                              key={index}
                              className={BUTTON_ORDER_STATE_COLOR_CLASS(st.state)}
                              onClick={()=> {
                                //reason
                                if (st.state === ORDER_STATE.RESEND) {
                                  setReasonModalClass(CUSTOM_MODAL_CLASS.SHOW);
                                } else {
                                  onNextStateClick(orderInfo?.id, st.state)
                                }
                              }
                              }
                          >
                            {
                              st.state === 1? '주문 하기' : t(ORDER_STATE_NAME(st.state))
                            }

                          </button>
                      ))
                }

              </Flex>
            </div>

            {/*{*/}
            {/*  isAttachFilesByState(orderInfo?.state) ? (*/}
            {/*      <>*/}
            {/*        <br/>*/}
            {/*        <Card>*/}
            {/*          <List*/}
            {/*              dataSource={orderInfo?.attaches}*/}
            {/*              renderItem={(item) => (*/}
            {/*                  <List.Item*/}
            {/*                      // actions={!item.fileKey*/}
            {/*                      //     ? [<a onClick={() => setActFindFileKey(new Date())} key='find-file-file'>{t("file.key")}</a>]*/}
            {/*                      //     : [item.fileKey]}*/}
            {/*                  >*/}
            {/*                    <List.Item.Meta*/}
            {/*                        avatar={<Avatar src={FILE_AVATA_ICON(item)}/>}*/}
            {/*                        title={item.charged ? t("file.charged") : t("file.free")}*/}
            {/*                        description={item.charged ? `${item.downloadCount} / ${item.maxDownload}` : ''}*/}
            {/*                    />*/}
            {/*                    <a onClick={() => setDownloadFileInfo({*/}
            {/*                      charged: item.charged,*/}
            {/*                      downloadCount: item.downloadCount,*/}
            {/*                      maxDownload: item.maxDownload,*/}
            {/*                      id: item.id,*/}
            {/*                      name: item.name,*/}
            {/*                      url: MK_DOWNLOAD_URL(item.url, {*/}
            {/*                        container: 'order',*/}
            {/*                        containerId: NUMBER(orderInfo?.id)*/}
            {/*                      } as SECURE_DOWNLOAD_OPTION),*/}
            {/*                      contentType: item.contentType*/}
            {/*                    } as FileListDto)*/}
            {/*                    }>{item.name}</a>*/}
            {/*                    <div className="file-revision">{item.revision}</div>*/}


            {/*                  </List.Item>*/}
            {/*              )}*/}
            {/*          />*/}
            {/*        </Card>*/}
            {/*      </>*/}
            {/*  ) : null*/}
            {/*}*/}
            <br/>
            <h3>{t('title.order_history')}</h3>
            <div className={"wrap_btn"}>
              <Card>
                <Timeline mode='left'
                          items={histories} />
                {
                  historySearchOption.enabled ?
                      <Flex justify={"center"}>
                        <Button onClick={()=>loadOrderHistory(orderInfo?.id, 5)}>더보기</Button>
                      </Flex> : null

                }


              </Card>
            </div>
          </div>

          <div>
            <section className={"order_detail_notice_text"}>
              <h4>유의사항</h4>
              <p>{t('message.required.detailNotice.0')}<span className={"keypoint_word"}>{t('message.required.detailNotice.1')}</span>{t('message.required.detailNotice.2')}</p>
              <p>{t('message.required.detailNotice.3')}</p>
              <p>{t('message.required.detailNotice.4')}</p>
              <p>{t('message.required.detailNotice.5')}</p>
              <p>{t('message.required.detailNotice.6')}</p>
            </section>
          </div>

          <div className={`wrap_popup popup_application ${reasonModalClass}`}>
            <div className="popup_box">
              <div className="popup_top">
                <h3>재발송 신청</h3>
                <button type="button" className="popup_close" onClick={() => {
                  setReasonModalClass(CUSTOM_MODAL_CLASS.CLOSE);
                  setReason("");
                }}>닫기</button>
              </div>

              <form action="">
                <div className="popup_content">
                  <p>재발송 신청 사유를 작성해주세요. (50자 이내)</p>

                  <section className="width100"><input type="text" className="des_input" value={reason} onChange={(e)=> setReason(e.target.value) }/></section>
                </div>

                <div className="form_btn">
                  {/*<button type="button" className="des_btn_default des_btn_main des_btn_cancel">취소</button>*/}
                  <button type="button" className="des_btn_default des_btn_main" onClick={()=> onNextStateReSendClick(orderInfo?.id)}>재발송 신청</button>
                </div>
              </form>
            </div>
          </div>

        </main>
        {/*<Descriptions title={t('title.order_info')}  bordered items={items} />*/}
        {modalContextHolder}
      </>
  )
}