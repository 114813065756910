
export const LANGUAGES = [
    { label: "한국어", code: "ko" },
    { label: "English", code: "en" },
];

export enum MAPPING {
    normal = 'normal',
    detail = 'detail',
    list = 'list',
    id = 'id'
}

// Pege items (한페이지에 몇개를 보여줄 것인가)
export const ITEMS_PER_PAGE = 2;

// Colors
export enum COLOR {
    gray = 'lightGray',
    red_01 = 'red',
    red_02 = 'orangeRed',
    red_03 = 'coral',
    yellow_00 = 'gold',
    yellow_01 = 'lemonChiffon',
    yellow_03 = 'yellow',
    purple_01 = 'purple',
    purple_02 = 'magenta',
    purple_03 = 'violet',
    green_01 = 'green',
    green_02 = 'limeGreen',
    green_03 = 'chartreuse',
    blue_01 = 'blue',
    blue_02 = 'dodgerBlue',
    blue_03 = 'skyBlue',
}


// Parameter names
export enum PARAM {
    ORDER_STATE = 'orderState',
    SEARCH_PREFIX = 's_'
}

// Const variables
export enum VAR {
    ORDER_STATE_ORDER = 'ORDER',
    ORDER_STATE_CONFIRM = 'CONFIRM',
    ORDER_STATE_RESEND = 'RESEND',
    ORDER_STATE_CLOSE = 'SEND;CLOSE',
}

export enum SPAN_CLASS{
    ORDER='order_stage order_stage1',
    CONFIRM='order_stage order_stage2',
    UNCONFIRMED='order_stage order_stage4',
    REJECT='order_stage order_stage4',
    SEND='order_stage order_stage2',
    RESEND='order_stage order_stage3',
    CANCEL='order_stage order_stage4',
    CLOSE='order_stage order_stage4',
    REFUND='order_stage order_stage4'
}

export enum BUTTON_CLASS{
    ORDER='des_btn_default des_btn_stage_default des_btn_stage1',
    CONFIRM='des_btn_default des_btn_stage_default des_btn_stage2',
    UNCONFIRMED='des_btn_default des_btn_stage_default des_btn_stage4',
    REJECT='des_btn_default des_btn_stage_default des_btn_stage4',
    SEND='des_btn_default des_btn_stage_default des_btn_stage2',
    RESEND='des_btn_default des_btn_stage_default des_btn_stage3',
    CANCEL='des_btn_default des_btn_stage_default des_btn_stage2',
    CLOSE='des_btn_default des_btn_stage_default des_btn_stage4',
    REFUND='des_btn_default des_btn_stage_default des_btn_stage4'
}